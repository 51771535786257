import { routePaths } from 'appConfig';
import { Icon, IconBox, IconIconSet, Link } from 'components/common';
import { useUserReferralCode } from 'lib/referral';
import { CSSProperties, useEffect, useMemo, useState } from 'react';

interface Props {
  iconSize?: number;
  iconColor?: CSSProperties['color'];
  className?: string;
}

export const ReferralCodeSummary = ({
  iconSize = 36,
  iconColor = '#000',
  className,
}: Props) => {
  const { referralCode } = useUserReferralCode();

  const noReferralTitle = useMemo(
    () => <span>Give a friend 10% OFF</span>,
    [],
  );
  const noReferralContent = "& You'll get 10% OFF!";

  const [title, setTitle] = useState(noReferralTitle);
  const [content, setContent] = useState(noReferralContent);

  useEffect(() => {
    const updateReferralCouponInformation = async () => {
      const hasReferralCode = referralCode !== null;
      if (hasReferralCode) {
        setTitle(
          <span className="text-uppercase">{referralCode}</span>,
        );
        setContent('is your referral code.');
        return;
      }

      setTitle(noReferralTitle);
      setContent(noReferralContent);
    };

    updateReferralCouponInformation();
  }, [referralCode, noReferralTitle]);

  return (
    <IconBox
      boxStyle={'icon-box-left'}
      className={`ml-4 ${className}`}
      icon={
        <Icon
          iconSet={IconIconSet.CUSTOM}
          name={'refer-a-friend'}
          size={iconSize}
          style={{
            color: iconColor,
            position: 'absolute',
            top: 0,
          }}
        />
      }
      title={title}
      text={
        <div className="mt-1">
          <p className="mb-3">{content}</p>
          <Link
            className="mb-0 mt-2 d-block text-primary-lighter"
            to={routePaths.USER_REFER_A_FRIEND}
          >
            <>
              My Referral Details{' '}
              <i className="icon-long-arrow-right"></i>
            </>
          </Link>
        </div>
      }
    />
  );
};
