import { routePaths } from 'appConfig';
import { Icon, IconBox, IconIconSet, Link } from 'components/common';
import { Price } from 'lib/core';
import datetime from 'lib/datetime';
import {
  POINT_NAME,
  useNearestExpiredLoyaltyPoint,
} from 'lib/loyaltyProgram';
import { numberWithComma } from 'lib/utils';
import { useEffect, useState } from 'react';
import { CSSProperties } from 'react';

interface Props {
  iconSize?: number;
  iconColor?: CSSProperties['color'];
  className?: string;
}

export const LoyaltyPointSummary = ({
  iconSize = 36,
  iconColor = '#000',
  className,
}: Props) => {
  const {
    totalLoyaltyPoints,
    totalLoyaltyPointsInDollar,
    oldestLoyaltyPoint,
  } = useNearestExpiredLoyaltyPoint();

  const formatRemainingLoyaltyPoints = (loyaltyPoint: number) =>
    `${POINT_NAME} Balance: ${numberWithComma(loyaltyPoint)}`;

  const getContent = () => {
    if (totalLoyaltyPoints > 0) {
      return (
        <>
          <Price value={totalLoyaltyPointsInDollar} /> worth of
          discounts.
        </>
      );
    }

    return noLoyaltyPointContent;
  };

  const getExpiredCopywritting = () => {
    if (oldestLoyaltyPoint === undefined) {
      return <></>;
    }

    const formattedPoint = numberWithComma(
      oldestLoyaltyPoint.balance_point,
    );

    const expiredAt = datetime(oldestLoyaltyPoint.expired_at);
    const formattedExpiredAt = expiredAt.format('D MMM h:mma');

    return (
      <>
        {formattedPoint} expires {formattedExpiredAt}.
      </>
    );
  };

  const noLoyaltyPointContent = <>Earn YB Reps to get discounts!</>;
  const noOldestLoyaltyPointContent = <></>;

  const [title, setTitle] = useState(formatRemainingLoyaltyPoints(0));
  const [content, setContent] = useState(noLoyaltyPointContent);

  const [expiredAtContent, setExpiredAtContent] = useState(
    noOldestLoyaltyPointContent,
  );

  useEffect(() => {
    const updateLoyaltyPointInformation = async () => {
      setExpiredAtContent(getExpiredCopywritting());
      setTitle(formatRemainingLoyaltyPoints(totalLoyaltyPoints));
      setContent(getContent());
    };

    updateLoyaltyPointInformation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oldestLoyaltyPoint?.id,
    oldestLoyaltyPoint?.balance_point,
    totalLoyaltyPoints,
  ]);

  return (
    <IconBox
      boxStyle={'icon-box-left'}
      className={`ml-4 ${className}`}
      icon={
        <Icon
          iconSet={IconIconSet.CUSTOM}
          name={'loyalty-point'}
          size={iconSize}
          style={{
            color: iconColor,
            width: '50px',
            position: 'absolute',
            top: 0,
          }}
        />
      }
      title={title}
      text={
        <div className="mt-1">
          <p className="m-0">{content}</p>
          <p>{expiredAtContent}</p>
          <Link
            className="mb-0 mt-2 d-block text-primary-lighter"
            to={routePaths.USER_LOYALTY_POINT}
          >
            <>
              Transaction History{' '}
              <i className="icon-long-arrow-right"></i>
            </>
          </Link>
        </div>
      }
    />
  );
};
